.sideMenu_container {
    width: 15%;
    background-color: #dcedf5;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px 0;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
}

.sideMenu_container ul {
    list-style-type: none;
    margin: 35px 0;
}

a {
    text-decoration: none;
}

.sideMenu_container ul li {
    display: flex;
    align-items: center;
    margin: 12px 0;
    color: #4ae;
    gap: 10px;
    font-size: 16px;
    padding: 10px 18px;
}

.user {
    padding: 5px 18px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sideMenu_container ul li:hover {
    background-color: #4ae;
    color: white;
    padding: 10px 18px;
}

.user_avatar {
    width: 30px;
    height: 30px;
}

.user_avatar img {
    width: 100%;
    border-radius: 50%;
}


.bar_icon {
    font-size: 1.5rem;
    display: none;
}

.modal {
    background-color: hsla(0, 0%, 0%, 0.75);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    left: 0;
    top: 0;
    position: fixed;
    overflow-x: hidden;
    transition: 1.5s;

}

.sidebar_container {
    width: auto;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px 0;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    overflow-x: hidden;
}

.sidebar_container ul li {
    display: flex;
    align-items: center;
    margin: 13px 0;
    color: #4ae;
    gap: 10px;
    font-size: 16px;
    padding: 10px 20px;
}

.sidebar_container ul li:hover {
    background-color: #4ae;
    color: white;
    padding: 10px 18px;
}

.close_button {
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 19px;
}

.testing {
    margin-top: 28px;
}



@media screen and (max-width: 900px) {
    .sideMenu_container {
        display: none;
        z-index: 2;
        position: absolute;
    }

    .bar_icon {
        display: block;
        position: absolute;
        top: 35px;
        right: 10px;
        z-index: 2;
    }
}