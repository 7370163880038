.admin {
  width: 95%;
  margin: auto;
}

.admin__logo {
  max-width: 200px;
}

.admin__upload-container {
  --column: 1;
  display: grid;
  grid-template-columns: repeat(var(--column), 1fr);
  gap: var(--heading-1);
}

@media screen and (min-width: 768px) {
  .admin__upload-container {
    --column: 2;
  }
}