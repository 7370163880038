.carousel__bg-image {
    background: center/100% no-repeat;
    text-align: center;
    padding-block: 100px;
    padding-inline: 10px;
}

.carousel__text {
    font-size: calc(var(--base-font-size) * 3);
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.075rem;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px -2px 1px rgba(0, 0, 0, 0.25),
        0px -2px 1px #cbcaca, 0px -2px 4px rgba(0, 0, 0, 0.25);
    word-break: break-word
}

@media screen and (min-width:780px) {
    .carousel__text {
        font-size: calc(var(--base-font-size) * 4);
    }
}

@media screen and (min-width: 1024px) {
    .carousel__text {
        font-size: calc(var(--base-font-size) * 6.25);
    }
}