.donate_container {
  width: 100%;
}

.hero_section {
  width: 100%;
  background-color: black;
  padding-block: 25px;
}

.hero_content {
  max-width: 950px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.donate_btn {
  width: 100%;
}

.hero_image {
  max-width: 500px;
  height: auto;
}

.hero_image img {
  width: 100%;
}

.hero_text {
  color: white;
  max-width: 325px;
  margin-bottom: 30px;
}

.hero_text h2 {
  color: #4ae;
  margin-bottom: 15px;
}

.hero_text p {
  margin: 0;
}

.hero_text p strong {
  color: green;
}

.donation_sub_container {
  width: 100%;
  background-color: white;
  height: 100%;
  padding: var(--container-padding)
}

.donation {
  max-width: 950px;
  margin: auto;
  padding-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.questions {
  max-width: 350px;
}

.first p,
.second p {
  margin: 0;
  text-align: justify;
}

.first h2 {
  color: #4aaa42;
}

.second h2 {
  margin-bottom: 13px;
  color: #4ae;
}

.first,
.second {
  margin-bottom: 18px;
}

.card_details {
  width: 350px;
  max-width: 100%;
}

.input_size {
  width: 350px;
}

.card_pin {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 25px;
  max-width: 100%;
}

.card_pin>* {
  max-width: 40%;
}

.card_save {
  display: flex;
  width: 90%;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .hero_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
  }

  .donation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 30px 0;
  }

  .questions {
    padding: 0 15px;
  }

  .card_details {
    max-width: 100%;
    margin: auto;
    padding: 0 15px;
  }
}