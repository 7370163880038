.modal {
    display: flex; 
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.6); 
    padding-top: 60px;
    color: black;
}
.modal_content{
        background-color: #fefefe;
        width: 35%; 
        padding: 18px;
        text-align: center;
        animation: animatezoom 0.6s;
        border-radius: 5px;
}

  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

.success{
    background-color: rgb(23, 156, 23);
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    margin: 10px;
}

.error{
    background-color: red;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    margin: 10px;
}



@media screen and (max-width: 600px) {
    .modal_content{
        width: 57%;
    }
}