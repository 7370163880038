* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* using !important was a faster way to enforce the font-family for the React-admin framework, don't blame me */
  font-family: Poppins, system-ui, sans-serif !important;
}

/* base styles */
:root {
  --heading-1: 1.875rem;
  --heading-2: 1.5rem;
  --heading-3: 1.25rem;
  --base-font-size: 1rem;
  --container-padding: 20px 12px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--base-font-size);
  margin-top: calc(var(--base-font-size) - 4px);
}

*::selection {
  background-color: #caf5b9;
}

h1 {
  font-size: var(--heading-1);
  margin-block: var(--heading-3);
}

h2 {
  font-size: var(--heading-2);
  margin-block: var(--heading-3);
}

h3 {
  font-size: var(--heading-3);
  font-weight: 600;
  margin-block: var(--heading-3);
}

p {
  margin-block: 10px
}

a {
  color: #0397D6
}

svg:hover {
  cursor: pointer;
}

button,
input,
textarea,
select {
  border-radius: 10px;
  font-size: 100%;
}

input[type="checkbox"] {
  margin-top: 5px;
  transform: scale(1.2);
}

.margin-block {
  margin-block: 10px
}

button {
  padding-block: 16px;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;
}

button:hover:not(.no-hover) {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}

button:active:not(.no-active) {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
  box-shadow: 4px 4px 30px 0 rgba(3, 151, 214, 0.4);
}

input,
select {
  padding: 13px 16px;
}

textarea:focus,
input:focus,
select:focus {
  border: 2px solid black;
}

small {
  font-size: calc(var(--base-font-size) - 2px);
}

label {
  display: inline-block;
  font-size: var(--heading-3);
  font-weight: 500;
  margin-bottom: 7px;
}

label.check {
  font-weight: 400;
  font-size: calc(var(--heading-3) -2);
}

img {
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

/*  style to add margins around each page content, and add id:nomargin to any element to exclude from margin styles */
.page__container :not(.no-page-margin) {
  padding: 20px 12px;
}

@media screen and (min-width: 768px) {
  body {
    margin-top: var(--heading-1);
  }

  h1 {
    font-size: calc(var(--heading-1) + 2px);
  }

  .page__container :not(.no-page-margin) {
    padding: 20px 64px;
  }

  :root {
    --container-padding: 20px 64px;
  }
}

@media screen and (min-width: 1024px) {
  body {
    font-size: var(--heading-3);
    margin-top: calc(var(--heading-1) + 10px);
  }

  small {
    font-size: calc(var(--base-font-size) + 2px);
  }

  h1 {
    font-size: calc(3 * var(--base-font-size));
  }

  h2 {
    font-size: var(--heading-1);
  }

  h3 {
    font-size: var(--heading-2);
  }

  label {
    font-size: var(--heading-2);
  }

  .page__container {
    padding: 20px 120px;
  }

  :root {
    --container-padding: 20px 120px;
  }
}