.details_container {
    width: 95%;
    margin: auto;
    padding-bottom: 25px;
}

.heading {
    text-align: center;
    margin-bottom: 20px;
}

.form_container {
    max-width: 400px;
    margin: auto;
}

.form_control {
    width: 100%;
    margin: 20px auto;
}

.submit_container button {
    background-color: #4ae;
    padding: 10px 15px;
    color: white;
    border-radius: 6px;
}

.submit_btn {
    background: transparent;
    border: none;
    color: white;
    font-weight: bolder;
    background-color: rgb(89, 89, 245);
    text-align: center;
    padding: 13px;
    border-radius: 5px;
    width: 100%;
}

.error_text {
    color: tomato;
    font-size: 14px;
}


.check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0;
}

.teams_container {
    background-color: white;
    color: black;
    padding: 8px 13px;
    width: 100%;
    height: 180px;
    overflow-y: auto;
    border-radius: 5px;
    border: 1.95px solid black;
    margin-top: 5px;
}

.teams_container p {
    color: rgba(0, 0, 0, 0.3);
}

.submit_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.submit_container button {
    border: none;
}

@media screen and (max-width: 600px) {
    .details_container {
        padding: 0 15px;
    }
}